import React, { useEffect } from 'react';
import { navigate, graphql } from 'gatsby';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import 'abortcontroller-polyfill'; // For IE
import 'isomorphic-fetch';

import { API } from '../settings';
import { HelmetBlock } from '../components/HelmetBlock';
import { Header } from '../components/HeaderBlock';

import loader from '../images/loader.svg';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/index.css';

const Success = ({ data, location }) => {
    const isDebugMode = typeof window !== `undefined` && window.localStorage.getItem('debugMode') === 'on';

    useEffect(() => {
        // NOTE: https://lowmess.com/blog/fetch-with-timeout
        const fetchWithTimeout = (uri, options = {}, time = 5000) => {
            const controller = new AbortController();
            const config = { ...options, signal: controller.signal };
            const timeout = setTimeout(() => {
                controller.abort();
            }, time);

            return fetch(uri, config)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`${response.status}: ${response.statusText}`);
                    }
                    return response;
                })
                .catch((error) => {
                    if (error.name === 'AbortError') {
                        throw new Error('Response timed out');
                    }
                    throw new Error(error.message);
                });
        };

        fetchWithTimeout(
            // NOTE: timeout test
            /*"https://httpstat.us/200?sleep=16000",
            { headers: { Accept: "application/json" } },*/
            API.SEND_FORM,
            {
                ...API.HEADERS.POST,
                body: JSON.stringify(location.state.fields),
            },
            15000
        )
            .then((response) => response.json())
            .then((result) => {
                if (isDebugMode) {
                    console.debug('API response:', result);
                }

                typeof window !== 'undefined' && window.gtag && window.gtag('event', 'Submit4success');

                if (result.status === 'OK' && typeof window !== 'undefined') {
                    typeof window !== 'undefined' && window.fbq && window.fbq('track', 'Lead');

                    window.location.href = result.partnerUrl;
                } else {
                    navigate('/reject');
                }
            })
            .catch((error) => {
                console.error(error.message);
                typeof window !== 'undefined' && window.gtag && window.gtag('event', 'Submit4success');

                navigate('/reject');
            });
    });

    return (
        <StyledPageWrapperBlock>
            <HelmetBlock data={data.wpPage} />
            <Header data={data.wpPage} />
            <StyledFormBlock>
                <Container>
                    <div className="page-heading">
                        <img src={loader} alt="" aria-hidden="true" />
                        <p>
                            En breve te vamos a redirigir a la mejor opción.
                            <br />
                            Sigue con la solicitud del préstamo en la siguiente página.
                        </p>
                    </div>
                </Container>
            </StyledFormBlock>
        </StyledPageWrapperBlock>
    );
};

export default Success;

const StyledPageWrapperBlock = styled.div`
    min-height: 100vh;
    background: #f2f6fc;

    header {
        background: transparent;
    }
`;

const StyledFormBlock = styled.header`
    .page-heading {
        position: relative;
        margin: 180px 0 0;
        color: #378aff;
        font-size: 16px;
        font-weight: 700;
        text-align: center;

        @media (min-width: 768px) {
            margin: 210px 0 0;
            font-size: 28px;
        }

        img {
            position: absolute;
            top: -100px;
            left: 50%;
            transform: translateX(-50%);
            animation: spin 1s linear infinite;
        }
    }

    @keyframes spin {
        100% {
            transform: translateX(-50%) rotate(360deg);
        }
    }
`;

export const pageQuery = graphql`
    query ($id: String!) {
        wpPage(id: { eq: $id }) {
            id
            title
            databaseId
            parentDatabaseId
            seo {
                metaDesc
                title
            }
        }
    }
`;
